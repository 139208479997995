<template>
  <div>
    <v-card flat class="grey lighten-5">
        <v-container class="grey lighten-5 fill-height">
            <v-card :shaped="true" :raised="true" width="100%" class="mb-6">
                <div class="align-center mb-4 pt-4" style="background: #FFFFFF; box-shadow: 0px 3px 12px #0000000F; width: 100%; height: 80px;">
                    <v-avatar class="ml-8" size="46" tile>
                        <v-icon >$vuetify.icons.ecard</v-icon>
                    </v-avatar>
                    <span class="title" style="color: #00D79E;">Djemari Salon and Spa</span>
                    <div class="float-right align-center mr-6">
                        <v-btn class="mx-2" depressed fab dark small color="#FDF1EF" style="border: 1px solid #E74C3C;">
                            <v-icon color="#E74C3C">mdi-trash-can-outline</v-icon>
                        </v-btn>
                    </div>
                </div>
                <div class="px-4 mb-4 mt-6">
                    <v-card v-for="(item, idx) in merchantList" :key="idx" raised class="mb-4" height="104px" style="border-radius: 8px;">
                        <v-row style="position: absolute; top: -12px; width: 100%;">
                            <v-col :md="4">
                                <div class="pl-6 pt-4" :style="item.background">
                                    <div style="font-size: 30px; color: #FFFFFF">Rp. 1.000.000</div>
                                    <div class="subtitle-1" style="color: #FFFFFF">{{ lang.t('$vuetify.expiry_period') }}: 6 months</div>
                                </div>
                            </v-col>
                            <v-col :md="2" class="pt-8">
                                <div class="body-1" style="color: #6A6A6A">{{ lang.t('$vuetify.price') }}</div>
                                <div>
                                    <span class="title font-weight-bold">900.000</span>
                                </div>
                            </v-col>
                            <v-col :md="4" class="pt-8">
                                <div class="body-1" style="color: #6A6A6A">{{ lang.t('$vuetify.date') }}</div>
                                <div>
                                    <span class="title font-weight-bold">Sun,Mon,Wed</span>
                                </div>
                            </v-col>
                            <v-col class="pt-9 mt-2 text-right">
                                <span class="subtitle-1 font-weight-bold" style="color: #1A73E8" @click="editCard">{{ lang.t('$vuetify.edit') }}</span>
                            </v-col>
                        </v-row>
                    </v-card>
                </div>
            </v-card>
            <div>
                <span class="subtitle-1" style="color: #BEC2C4">{{ lang.t('$vuetify.created_by') }}</span>
                <div>
                    <v-avatar size="28" class="mr-3">
                        <v-img src="@/assets/Screenshot(4).png"></v-img>
                    </v-avatar>
                    <span class="subtitle-1 font-weight-bold">Andi Suseno</span>
                    <span class="subtitle-1" style="color: #BEC2C4"> at </span>
                    <span class="subtitle-1 font-weight-bold">15 Feb 2020, 13:30</span>
                </div>
            </div>
            <v-dialog
              v-model="dialog"
              max-width="70%">
              <v-card class="pb-5">
                <v-toolbar color="elevation-0">
                  <v-toolbar-title >
                    <span class="ml-4 font-weight-bold">{{ lang.t('$vuetify.edit') }} {{ lang.t('$vuetify.ecard') }}: </span>
                    <span style="color: #00D79E">Djemari Salon and Spa</span><br>
                    <div class="body-2 ml-4" style="color: #BEC2C4">{{ lang.t('$vuetify.detail') }} {{ lang.t('$vuetify.ecard') }}</div>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn icon @click="dialog = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <div class="mx-6 mt-6">
                    <div class="ml-2">
                    <v-row>
                      <v-col>
                        <v-card raised>
                            <v-card-title>
                              <v-row>
                                <v-col :md="3">
                                  e-Card 1
                                </v-col>
                                <v-col class="text-right">
                                  <div>
                                    <v-btn rounded small depressed style="background: #FDF1EF 0% 0% no-repeat padding-box;border: 1px solid #E74C3C;color: #E74C3C" @click="delCard">{{ lang.t('$vuetify.delete_ecard') }}</v-btn>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-card-title>
                            <div>
                                <hr style="border-top: 1px dashed #E0E0E0">
                                <div class="ml-6">
                                    <v-row>
                                    <v-col :md="6" >
                                        <div class="justify-space-between">
                                            <div class="d-flex flex-no-wrap justify-space-between mb-3">
                                                <span style="color: #BEC2C4">{{ lang.t('$vuetify.amount') }}</span>
                                            </div>
                                            <v-autocomplete v-model="selectCity" :items="amountList" outlined dense ></v-autocomplete>
                                            <div class="d-flex flex-no-wrap justify-space-between" style="margin-top: -20px; margin-bottom: -25px">
                                              <span class="mt-4" style="color: #BEC2C4">{{ lang.t('$vuetify.discount') }}</span>
                                              <v-switch
                                                v-model="switch1"
                                              ></v-switch>
                                            </div>
                                            <v-radio-group v-model="coloum" row>
                                              <v-radio label="Percentage" value="1"></v-radio>
                                              <v-radio label="Amount" value="2"></v-radio>
                                            </v-radio-group>
                                            <div v-if="coloum === '1'">
                                              <v-text-field
                                                v-model="amount"
                                                suffix="%"
                                                outlined
                                                dense
                                                type="number"
                                              ></v-text-field>
                                            </div>
                                            <div v-else>
                                              <v-row class="mt-n3 mb-n3">
                                                <v-col :md="4">
                                                  <v-autocomplete
                                                    v-model="selectCity"
                                                    :items="['Rp', '$']"
                                                    outlined
                                                    dense
                                                    ></v-autocomplete>
                                                </v-col>
                                                <v-col>
                                                  <v-text-field
                                                    v-model="amount"
                                                    outlined
                                                    dense
                                                    type="number"
                                                  ></v-text-field>
                                                </v-col>
                                              </v-row>
                                            </div>
                                            <div class="d-flex flex-no-wrap justify-space-between mb-3">
                                                <span style="color: #BEC2C4">{{ lang.t('$vuetify.ecard_background') }}</span>
                                            </div>
                                            <v-radio-group v-model="row" row>
                                              <div class="text-center mr-7">
                                                <div style="width: 64px; height: 64px;background: transparent linear-gradient(143deg, #FF0099 0%, #493240 100%) 0% 0% no-repeat padding-box;border-radius: 8px;"></div>
                                                <div class="float-right mt-2">
                                                  <v-radio value="radio-1"></v-radio>
                                                </div>
                                              </div>
                                              <div class="text-center mr-7">
                                                <div style="width: 64px; height: 64px;background: transparent linear-gradient(143deg, #FF6CAB 0%, #7366FF 100%) 0% 0% no-repeat padding-box;border-radius: 8px;"></div>
                                                <div class="float-right mt-2">
                                                  <v-radio value="radio-2"></v-radio>
                                                </div>
                                              </div>
                                              <div class="text-center">
                                                <div style="width: 64px; height: 64px;background: transparent linear-gradient(143deg, #EE0979 0%, #FF6A00 100%) 0% 0% no-repeat padding-box;border-radius: 8px;"></div>
                                                <div class="float-right mt-2">
                                                  <v-radio value="radio-3"></v-radio>
                                                </div>
                                              </div>
                                            </v-radio-group>
                                        </div>
                                    </v-col>
                                    <v-col :md="6" >
                                        <div class="justify-space-between mr-6">
                                            <div class="d-flex flex-no-wrap justify-space-between" >
                                                <span class="mt-1 mb-2" style="color: #BEC2C4">{{ lang.t('$vuetify.expiry_period') }}</span>
                                            </div>
                                            <v-autocomplete v-model="selectCity" :items="['1 Month', '2 Month', '3 Month']" outlined dense ></v-autocomplete>
                                            <div class="d-flex flex-no-wrap justify-space-between" style="margin-top: -20px;">
                                              <span class="mt-4" style="color: #BEC2C4">{{ lang.t('$vuetify.day_limit') }}</span>
                                              <v-switch
                                                v-model="switch1"
                                              ></v-switch>
                                            </div>
                                            <v-autocomplete v-model="selectCity" :items="['Every Day', '1 Week', '1 Month']" outlined dense ></v-autocomplete>
                                            <div class="mt-9">
                                              <span class="caption" style="color: #BEC2C4">{{ lang.t('$vuetify.preview') }} :</span>
                                              <div style="box-shadow: 0px 0px 12px #0000001F; border-radius: 12px;">
                                                <v-img
                                                  src="@/assets/ecard-bg.png"
                                                  class="white--text align-end"
                                                  height="115px">
                                                  <div class="ml-4">
                                                    <div>
                                                      <span class="caption mr-8" style="text-align: left; color: #00D79E;">Djemari Salon and Spa</span>
                                                    </div>
                                                    <div :md="6">
                                                      <span class="caption mr-8" style="text-align: left; color: #00D79E;">1.000.000</span>
                                                    </div>
                                                  </div>
                                                  <div class="text-right mr-4 mt-5">
                                                    <div >
                                                      <div style="font-size: 7px; color: #00D79E;">{{ lang.t('$vuetify.avaiable_until') }}</div>
                                                      <div style="font-size: 9px; color: #00D79E;">08/21</div>
                                                    </div>
                                                  </div>
                                                </v-img>
                                              </div>
                                            </div>
                                        </div>
                                    </v-col>
                                    </v-row>
                                    </div>
                            </div>
                        </v-card>
                        <div class="text-center mt-6">
                            <v-btn width="552px" rounded color="#00D79E" style="color: #FFFFFF">{{ lang.t('$vuetify.save') }}</v-btn>
                        </div>
                      </v-col>
                    </v-row>
                    </div>
                </div>
              </v-card>
            </v-dialog>
        </v-container>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'
import echarts from 'echarts/lib/echarts'

// import LocationDetail from './_location-detail'
// import LocationStaff from './_location-staff'
// import LocationSales from './_location-sales'
export default {
  name: 'ReportMerchant',
  components: {},
  data () {
    return {
      search: '',
      tab: null,
      tab2: null,
      address: 'Jalan Akasia, Jl. Seturan Raya No.1A, Kledokan, Condongcatur, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta 55281',
      items: [
        'Merchant List', 'Activity', 'Transaction', 'Acquisition'
      ],
      itemsTab: [
        'Profile', 'Location', 'Staff', 'Sales'
      ],
      alignments: [
        'center'
      ],
      merchantList: [
        {
          last_active: 'Yesterday',
          date: '23 January 2020, 13:30',
          detail: 'Andi created deals for Haircut - Short in Djemari Salon and Spa',
          background: 'width: 240px; height: 104px; background: transparent linear-gradient(145deg, #EE0979 0%, #FF6A00 100%) 0% 0% no-repeat padding-box; border-radius: 8px 0px 64px 8px;'
        },
        {
          last_active: 'A month ago',
          date: '23 January 2020, 13:30',
          detail: 'Andi Deleted e-Card for Haircut - Short in Djemari Salon and Spa',
          background: 'width: 240px; height: 104px; background: transparent linear-gradient(145deg, #FF6CAB 0%, #7366FF 100%) 0% 0% no-repeat padding-box; border-radius: 8px 0px 64px 8px;'
        },
        {
          last_active: '3 months ago',
          date: '23 January 2020, 13:30',
          detail: 'Andi created deals for Haircut - Short in Djemari Salon and Spa',
          background: 'width: 240px; height: 104px; background: transparent linear-gradient(145deg, #FF0099 0%, #493240 100%) 0% 0% no-repeat padding-box; border-radius: 8px 0px 64px 8px;'
        }
      ],
      headers: [
        {
          text: 'Merchant Name',
          align: 'left',
          value: 'name'
        },
        {
          text: 'Total Staff',
          align: 'center',
          value: 'total_staff'
        },
        {
          text: 'Platform',
          align: 'left',
          value: 'platform'
        },
        {
          text: 'City',
          align: 'left',
          value: 'city'
        },
        {
          text: 'Time Joined',
          align: 'right',
          value: 'time_joined'
        }
      ],
      desserts: [
        {
          name: 'Djemari Salon and Spa',
          city: 'Yogyakarta',
          total_staff: 12,
          platform: 'POS',
          time_joined: '13 Apr 2019 13:00',
          date: '21 Agustus 2019',
          time_left: '2 days'
        },
        {
          name: 'Florent Women Salon Specialist',
          city: 'Yogyakarta',
          total_staff: 12,
          platform: 'POS, Online',
          time_joined: '13 Apr 2019 13:00',
          date: '21 Agustus 2019',
          time_left: '2 days'
        },
        {
          name: 'Florent Women Salon Specialis',
          city: 'Yogyakarta',
          total_staff: 12,
          platform: 'POS, Online, Marketplace',
          time_joined: '13 Apr 2019 13:00',
          date: '21 Agustus 2019',
          time_left: '2 days'
        },
        {
          name: 'Kakiku Reflexologi',
          city: 'Batam',
          total_staff: 12,
          platform: 'POS',
          time_joined: '13 Apr 2019 13:00',
          date: '21 Agustus 2019',
          time_left: '2 days'
        }
      ],
      title: {
        text: 12,
        textStyle: {
          color: '#333333',
          fontSize: '69',
          fontStyle: 'normal'
        },
        x: 'center',
        y: 'center'
      },
      ringSettings: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        offsetY: 150,
        radius: [90, 130],
        label: {
          normal: {
            show: false,
            position: 'center'
          },
          emphasis: {
            show: false,
            textStyle: {
              fontSize: '30',
              fontWeight: 'bold'
            }
          }
        }
      },
      lineSettings: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#00D79E'
          }, {
            offset: 1,
            color: '#BEF4E6'
          }])
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false
      },
      extend: {
        'xAxis.0.axisLabel.rotate': 15
      },
      colorsRing: ['#FFCB9F', '#FFE78F', '#A1FFD1', '#C5DBFF'],
      colorsLine: ['#00D79E', '#1DA1F3'],
      dataRing: {
        columns: ['date', 'sales'],
        rows: [{ 'date': '10', 'sales': 20 },
          { 'date': '11', 'sales': 45 },
          { 'date': '12', 'sales': 30 },
          { 'date': '13', 'sales': 40 }
        ]
      },
      histogramSettings: {
        metrics: ['confirmed', 'cancelled'],
        stack: { 'sales': ['confirmed', 'cancelled'] }
      },
      colorsHisto: ['#00D79E', '#F5295A'],
      dataUpcomingAppointment: {
        columns: ['date', 'confirmed', 'cancelled'],
        rows: [{ 'date': 'Mon 10', 'confirmed': 2, 'cancelled': 2 },
          { 'date': 'Tue 11', 'confirmed': 2, 'cancelled': 0 },
          { 'date': 'Wed 12', 'confirmed': 4, 'cancelled': 0 },
          { 'date': 'Thr 13', 'confirmed': 2, 'cancelled': 0 },
          { 'date': 'Fri 14', 'confirmed': 6, 'cancelled': 2 },
          { 'date': 'Sat 15', 'confirmed': 7, 'cancelled': 0 },
          { 'date': 'Sun 16', 'confirmed': 3, 'cancelled': 2 }
        ]
      },
      date: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      date2: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      menu1: false,
      menu2: false,
      dropdown_font: ['All City', 'Yogyakarta', 'Batam', 'Jakarta'],
      selectCity: '',
      page: 1,
      total_page: 10,
      dialog: false,
      coloum: '1'
    }
  },

  computed: {
    lang () {
      return this.$vuetify.lang
    },

    computedDateFormattedMomentjs () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date[0] ? moment(this.date[0]).format('D') : ''
      endDate = this.date[1] ? moment(this.date[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    },
    computedDateFormattedMomentjs1 () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date2[0] ? moment(this.date2[0]).format('D') : ''
      endDate = this.date2[1] ? moment(this.date2[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    },
    activeFilter () {
      if (this.model == null) return undefined
      return this.filters[this.model].fn
    }
  },

  methods: {
    setDate (param) {
      if (param === 0) {
        if (this.date.length > 1) {
          this.menu1 = false
        }
      } else {
        if (this.date2.length > 1) {
          this.menu2 = false
        }
      }
    },

    editCard () {
      this.dialog = true
    }
  }
}
</script>
